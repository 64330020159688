import { Link } from 'react-router-dom';

import './App.css';

function App() {
  return (
    <div className='homeMain'>
      <div className='centerButtons'>
        <Link to='/about'>
          <button className='buttonsMenu'>About</button>
        </Link>
        <Link to='/test'>
          <button className='buttonsMenu'>Test</button>
        </Link>
        <Link to='cssexercise'>
          <button className='buttonsMenu'>Css Exercise Page</button>
        </Link>
        <Link to='gallery'>
          <button className='buttonsMenu'>Gallery</button>
        </Link>
      </div>
      <h1 className='title'>Home Page</h1>
    </div>
  );
}

export default App;
