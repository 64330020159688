import './CssExercisePage.css';

function CssExercisePage() {
  return (
    <>
      <h1 className='exerciseHeading'>Exercise Page</h1>
      <h3 className='exerciseHeading'>Exercise Box Model</h3>
      <div className='cardBorder'>
        <img
          className='cardImage'
          src='https://assets.codepen.io/123865/dog.png'
          alt='dogimage'
        />
        <p className='cardText'>I Am Dog</p>
      </div>
      <h3 className='exerciseHeading'>Exercise Button Hover Effect</h3>
      <div className='dark-background'>
        <button className='exerciseButton'>Hover Me</button>
      </div>
    </>
  );
}

export default CssExercisePage;
