import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutPageComponent from './components/AboutPageComponent/AboutPageComponent';
import TestPageComponent from './components/TestPageComponent/TestPageComponent';
import CssExercisePage from './components/CssExercisePage/CssExercisePage';
import GalleryPage from './components/GalleryPage/GalleryPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='test' element={<TestPageComponent />} />
      <Route path='about' element={<AboutPageComponent />} />
      <Route path='cssexercise' element={<CssExercisePage />} />
      <Route path='gallery' element={<GalleryPage />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
