import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../TestPageComponent/TestPageComponent.css';

function TestPageComponent() {
  function calculate() {
    let firstNum = 2;
    let secondNum = 2;
    let result = 0;
    result = firstNum + secondNum;
    return result;
  }
  let resultOfCalculation = calculate();
  let resultBackground = 'calculateResultField';

  const [colour, setColor] = useState('blueColour');

  function changeColor() {
    if (colour === 'blueColour') {
      setColor('greenColour');
    } else {
      setColor('blueColour');
    }
  }
  return (
    <>
      <h1>Test Page Component</h1>
      <p>Test page component for testing purposes.</p>

      <div className={colour}>
        Result of calculation: {resultOfCalculation} Other: {colour}
      </div>

      <button onClick={() => changeColor()}>Change Background</button>

      <div>
        <Link to='/'>
          <button>Back to Home Page</button>
        </Link>
      </div>
    </>
  );
}

export default TestPageComponent;
