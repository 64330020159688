import { Link } from 'react-router-dom';

function AboutPageComponent() {
  return (
    <>
      <h1>About Page Component</h1>
      <p>About page component for testing purposes.</p>
      <button>
        <Link to='/test'>TestPageComponent</Link>
      </button>
      <button>
        <Link to='/'> Back to Home Page</Link>
      </button>
    </>
  );
}

export default AboutPageComponent;
