import classes from '../GalleryPage/GalleryPage.module.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function GalleryPage() {
  const [IsHovering, setIsHovering] = useState(false);

  const handleMoseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  function menuButton() {
    if (IsHovering) {
      return (
        <div>
          <Link to='/'>Home Page</Link>
        </div>
      );
    } else {
      return <div>Black & White</div>;
    }
  }

  return (
    <>
      <nav
        className={classes.topNav}
        onMouseOver={handleMoseOver}
        onMouseOut={handleMouseOut}>
        {menuButton()}
        {/* {!IsHovering && <div>Show When NOT Hover</div>}
        {IsHovering && <div>Show When Hover</div>} */}
      </nav>

      {/* <!-- Massimo Margagnoni --> */}
      <img
        className={[classes.imageSize, classes.images].join(' ')}
        src='http://c1.staticflickr.com/9/8450/8026519634_f33f3724ea_b.jpg'></img>
      <img
        className={[classes.imageSize, classes.images].join(' ')}
        src='http://c2.staticflickr.com/8/7218/7209301894_c99d3a33c2_h.jpg'></img>
      <img
        className={[classes.imageSize, classes.images].join(' ')}
        src='http://c2.staticflickr.com/8/7231/6947093326_df216540ff_b.jpg'></img>
      {/* <!-- Giuseppe Milo -->*/}
      <img
        className={[classes.imageSize, classes.images].join(' ')}
        src='http://c1.staticflickr.com/9/8788/17367410309_78abb9e5b6_b.jpg'></img>
      <img
        className={[classes.imageSize, classes.images].join(' ')}
        src='http://c2.staticflickr.com/6/5814/20700286354_762c19bd3b_b.jpg'
        alt='giusepe-second-image'
      />
      <img
        className={[classes.imageSize, classes.images].join(' ')}
        src='http://c2.staticflickr.com/6/5647/21137202535_404bf25729_b.jpg'
        alt=''
      />
      {/* <!-- Görlitz Photography --> */}
      <img
        className={[classes.imageSize, classes.images].join(' ')}
        src='http://c2.staticflickr.com/6/5588/14991687545_5c8e1a2e86_b.jpg'
        alt=''
      />
      <img
        className={[classes.imageSize, classes.images].join(' ')}
        src='http://c2.staticflickr.com/4/3888/14878097108_5997041006_b.jpg'
        alt=''
      />
      <img
        className={[classes.imageSize, classes.images].join(' ')}
        src='http://c2.staticflickr.com/8/7579/15482110477_0b0e9e5421_b.jpg'
        alt=''
      />
      {/*   <!-- All Photos Licensed Under Creative Commons 2.0 -->
    <!-- https://creativecommons.org/licenses/by/2.0/legalcode  --> */}
    </>
  );
}

export default GalleryPage;
